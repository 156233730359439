import { UserService } from '../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { User } from '../../../models/user';
import { DialogContentLogin } from '../login-dialog/dialog-content-login';
import { MatDialog } from '@angular/material/dialog';
import * as CryptoJS from 'crypto-js';

export interface DialogData {
  acceptInscription: boolean;
  email: any;
}

@Component({
  selector: 'dialog-content-inscription',
  templateUrl: 'dialog-content-inscription.html',
})
export class DialogContentInscription {

  inscriptionForm!: FormGroup;
  loading = false;
  submitted = false;
  returnUrl!: string;
  error = '';
  logo = 'assets/logo4.png';
  user!: User;
  successInscription : boolean = false;
  errorFetch: boolean = false;
  email!: string;
  dialogRef: any;
  cookiesNewsletter!: string | null;
  acceptNewsLetter: boolean = false;

  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string = "test";

  request!: string;
  responce!: string;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private userService: UserService,
      public dialog: MatDialog
  ) {
      // redirect to home if already logged in
      // if (this.authenticationService.userValue) {
      //     this.router.navigate(['/']);
      // }
  }

  ngOnInit() {
      this.inscriptionForm = this.formBuilder.group({
          email: ['', Validators.required],
          password: ['', Validators.required]
      });

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.inscriptionForm.controls; }

  onSubmit() {
      this.submitted = true;
      console.log("test 0809");
      // stop here if form is invalid
      if (this.inscriptionForm.invalid) {
          return;
      }
      console.log(this.f.email.value, this.f.password.value);

      this.loading = true;

      this.userService.inscription(this.inscriptionForm.value).subscribe((responseToken) => {
        console.log(responseToken);
        console.log(responseToken.access_token);
        this.loading = false;
        this.dialog.closeAll();
        // if (responseToken) {
        //   this.userService.checkUserNameAndPassword(this.f.email.value, this.f.password.value).subscribe((response) => {
        //     this.user = response
        //     if (response) {
        //       if (this.user?.active == true) {
        //         console.log(this.user);
        //         localStorage.setItem('role', this.user!.role);
        //         localStorage.setItem('id', this.user!._id);
        //         this.setConsent(1);
        //         this.successInscription = true;
        //         //this.router.navigate(['/admin/productsNgrx'])
        //         return true;
        //       }
        //     }
        //     console.log("5455445");
        //     this.loading = false;
        //     this.errorFetch = true;
        //     return false;
        //   });
        // }
        // this.encryptUsingAES256();
        // this.decryptUsingAES256()
      }, err => {
        console.log('Error: ' + err.message);
        this.loading = false;
        this.errorFetch = true;
        return false;
      });
  }

  encryptUsingAES256() {
    let _key = CryptoJS.enc.Utf8.parse(this.f.email.value);
    let _iv = CryptoJS.enc.Utf8.parse(this.f.password.value);

    // 🔐 Chiffrement AES
    let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(this.request),
        _key,
        {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB, // ⚠️ ECB n'utilise pas d'IV, préférer CBC
            padding: CryptoJS.pad.Pkcs7
        }
    );

    this.encrypted = encrypted.toString();
    alert("Texte chiffré: " + this.encrypted);
}

  decryptUsingAES256() {
    this.decrypted = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(this.encrypted));
    alert(this.decrypted);
  //   let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  //  // CryptoJS.enc.Base64.stringify(words)

  //   this.decrypted = CryptoJS.AES.decrypt(
  //     this.encrypted, _key, {
  //       keySize: 16,
  //       iv: _iv,
  //       mode: CryptoJS.mode.ECB,
  //       padding: CryptoJS.pad.Pkcs7
  //     }).toString(CryptoJS.enc.Utf8);
  }

  public setConsent(value: any): void {
    localStorage.setItem('cookies_login', value);
  }

  public openDialogLogin(): void {
    this.dialogRef = this.dialog.open(DialogContentLogin, {
      width: '550px',
      data: { acceptNewsLetter: this.acceptNewsLetter, email: this.email },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.acceptNewsLetter = result.acceptNewsLetter;
      this.email = result.email;
      if (result === false) {
        this.setConsent("0");
        this.cookiesNewsletter = "0";
      } else {
        this.setConsent("1");
        this.cookiesNewsletter = "1";
      }
    });

  }
}
