import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './../models/user';

let httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private isLoggedInSubject = new BehaviorSubject<boolean>(this.hasToken());
  isLoggedIn$ = this.isLoggedInSubject.asObservable();
  private loggedIn = new BehaviorSubject<boolean>(false); // par défaut non connecté
  loggedIn$ = this.loggedIn.asObservable();

  private hasToken(): boolean {
    return !!localStorage.getItem('token');
  }

  // Méthode pour mettre à jour l’état de connexion
  setLoginStatus(status: boolean) {
    this.loggedIn.next(status);

    return new Promise((resolve) => {
      setTimeout(() => {
        //localStorage.setItem('token', 'fake-jwt-token');
        this.isLoggedInSubject.next(true); // 🔥 Notifier tous les abonnés que l'utilisateur est connecté
        resolve(true);
      }, 1000);
    });
  }



  private subject = new Subject<any>();
  userObservable!: Observable<User>;
  currentUserSubject: any;
  constructor(private httpClient: HttpClient) { }
  isAuth = false;
  public host = environment.host;
  user!: User;
  jwtToken!: string | null;

  createNewUser(dataForm: any) {
    return new Promise<void>((resolve, reject) => {
      this.httpClient
        .post(this.host + '/createNewUser', dataForm)
        .toPromise()
        .then(
          () => {
            console.log('createNewUser');
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }


  signOutUser() {
    /* firebase.auth().signOut();*/
  }

  signIn() {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.isAuth = true;
        resolve(true);
      }, 2000);
    });
  }

  signOut() {
    this.isAuth = false;
    localStorage.removeItem('role');
    localStorage.removeItem('access_token');
    localStorage.removeItem('id');
  }


  public get userValue() {
    this.userObservable.subscribe(user => {
      this.user = user
    });
    return this.user;
  }

  public get currentUserValue(): ApplicationUser {
    return this.user;
  }



  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('access_token');
    localStorage.removeItem('cookies_login');
    this.isLoggedInSubject.next(false); // 🔥 Mettre à jour l’état
  }

  addJwtToHeaders(): void {
    this.jwtToken = localStorage.getItem('access_token');
    console.log(this.jwtToken);
    const auth = httpOptions.headers.get('Authorization');
    console.log(auth);

    if (!auth) {


      if (!this.jwtToken) return;
      httpOptions.headers = httpOptions.headers.append(
        'Authorization',
        `${this.jwtToken}`
      );
    }
  }

}

export interface ApplicationUser {
  access_token: string;
}
