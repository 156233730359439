<div class="dialog-letter">

  <div class="card-div">
    <mat-dialog-content class="mat-typography">
      <h1 mat-dialog-title><img [src]="logo" width="70" height="70" [alt]="logo" /> Bienvenue sur Promodeep !</h1>

      <div>
        <!-- <div class="alert alert-info">
          <img [src]="logo" width="50" height="50" [alt]="logo" />
        </div> -->
        <div class="card">
          <h1 class="card-header"> Connexion</h1>
          <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label style="display: flex;" for="username">E-mail</label>
                <input type="text" formControlName="username" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">Email requis</div>
                </div>
              </div>
              <div class="form-group">
                <label style="display: flex;" for="password">Mot de passe</label>
                <input type="password" formControlName="password" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Mot de passe requis</div>
                </div>
              </div>
              <button [disabled]="loading" class="btn btn-primary" cdkFocusInitial >
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Se connecter
              </button>

              <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
            <br>
            <a mat-flat-button>
              Mot de passe oublié ?
            </a>
          </div>
        </div>
      </div>
      <br>
      <button color="primary" (click)="openDialogInscription()" [mat-dialog-close]="true" mat-stroked-button>
        Créer un compte
      </button>
    </mat-dialog-content>

  </div>
  <!-- <div class="card-div">
    <mat-dialog-content class="mat-typography">
      <h1 mat-dialog-title>Inscription</h1>

      <div>
        <div class="alert alert-info">
          Username: test<br />
          Password: test
        </div>
        <div class="card">
          <h4 class="card-header">Angular 10 Basic Authentication Example</h4>
          <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label for="username">Username</label>
                <input type="text" formControlName="username" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" formControlName="password" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
              </div>
              <button [disabled]="loading" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Login
              </button>
              <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
          </div>
        </div>
      </div>




      <mat-slide-toggle><span>J'accepte de recevoir la newsletter de promodeep.com</span></mat-slide-toggle>
      <mat-slide-toggle><span>J'accepte de recevoir des publicités de nos partenaires</span></mat-slide-toggle>


      <br>
      <br>
      <p>Vous affirmez pris connaissance de notre <a class="verySmallText" routerLink="privacyPolicy">Politique de
          confidentialité.</a></p>
      <br>
      <h5>

      </h5>
    </mat-dialog-content>
    <mat-dialog-actions align="end">

    </mat-dialog-actions>
  </div> -->
</div>
