import { UserService } from './../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DialogContentInscription } from '../inscription-dialog/dialog-content-inscription';

@Component({
  selector: 'dialog-content-login',
  templateUrl: 'dialog-content-login.html',
})
export class DialogContentLogin implements OnInit {
  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  returnUrl!: string;
  error = '';
  logo = 'assets/logo4.png';
  user!: User | null;
  successLogin: boolean = false;
  errorFetch: boolean = false;
  deviceInfo!: any;
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktopDevice: boolean = false;
  acceptNewsLetter: boolean = false;
  email!: string;
  cookiesNewsletter!: string | null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogContentLogin> // Inject MatDialogRef pour fermer le dialogue
  ) {
    this.epicFunction();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // Récupérer l'URL de retour des paramètres ou définir une valeur par défaut
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // Accéder facilement aux champs du formulaire
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    console.log(this.f.username.value, this.f.password.value);
    this.loading = true;

    this.userService.login(this.f.username.value, this.f.password.value).subscribe((responseToken) => {
      if (responseToken) {
        // this.userService.checkUserNameAndPassword(this.f.username.value, this.f.password.value).subscribe((response) => {
        //   this.user = response;
          if (responseToken) {
            // localStorage.setItem('role', this.user!.role);
            // localStorage.setItem('id', this.user!._id);
            this.setConsent(1);
            this.successLogin = true;
            localStorage.setItem('token', responseToken.access_token);

            // Notifie AuthService pour mettre à jour la navbar et ferme le dialogue
            this.authService.setLoginStatus(true);
            this.dialogRef.close();

            // Navigue vers une autre route si nécessaire (optionnel)
            this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/dashboard']);
              window.location.reload()
            });
          } else {
            this.loading = false;
            this.errorFetch = true;
            this.error = "Error"
          }
        // });
      }
    }, err => {
      console.log('Error: ' + err.message);
      this.loading = false;
      this.errorFetch = true;
      this.error = "Login ou mot de passe incorrect"
    });
  }

  public setConsent(value: any): void {
    localStorage.setItem('cookies_login', value);
  }

  public openDialogInscription(): void {
    const dialogRef = this.dialog.open(DialogContentInscription, {
      width: '550px',
      data: { acceptNewsLetter: this.acceptNewsLetter, email: this.email },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.acceptNewsLetter = result.acceptNewsLetter;
      this.email = result.email;
      if (result === false) {
        this.setConsent("0");
        this.cookiesNewsletter = "0";
      } else {
        this.setConsent("1");
        this.cookiesNewsletter = "1";
      }
    });
  }

  private epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
}
