import { Component, Input, OnInit, HostListener, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FilterByPromotionCodeAction,
  FilterBySourceAction,
  FilterCategoryAction,
  GetAllProductsAction,
  SearchProductsAction,
  SearchProductsByPromotionCodeAction,
  SearchProductsBySourceAction,
  UpdateProductAction,
} from 'src/app/ngrx/products.action';
import { ProductsState } from 'src/app/ngrx/products.reducer';
import { Product } from 'src/app/models/product.model';
import { ProductDto } from './../../models/productDto.model';
import { ProductService } from 'src/app/services/product.service';
import { Observable } from 'rxjs';
import { TimeCalculator } from './../../shared/time-calcultor';
import { CookieService } from 'src/app/services/cookie.service';
import { categoryEnum, pathRoutingEnum } from 'src/assets/constantEnum';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/services/canonical.service';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @Input() state: ProductsState | null = null;
  logoScissors = 'assets/scissors.png';

  virtualProducts!: any;
  sortOrder!: number;

  sortField!: string;
  sortKey!: string;
  $event!: any;
  responsiveOptions: any;
  mobile: boolean = false;
  productCategory!: string;
  productSource!: string;
  productDto!: ProductDto;
  productsVedetteBrand!: Product[];
  productVedetteBrand!: Observable<Product[]>;
  dealBloced: boolean = false;
  dealWillBloced: boolean = false;
  squarePromotionPicture = 'assets/squarePromotion.png';
  copieCodePromotion = false;
  windowScrolled: any
  timeCalculator = new TimeCalculator;
  public href: string = "";
  promotionCodeSearch: boolean = false;
  diffAddDate!: string;
  diffEndDate!: string;
  viewNumber!: number;
  product!: Product;
  products$!: Observable<Product[]>;
  products: Product[] = [];
  productExtention: Product[] = [];
  visibleSidebar4: any;
  pages = new Array(3);
  productUrl!: string;
  codePromotionCopied !: string
  promotionCodeDisplay: boolean = false;
  limit: number = 10;
  skip: number = 0;
  scrollTop!: number;
  isGetProducts: boolean = true;
  bindingDataPageYOffset: number = 0;
  onScrollTopPageYOffset: number = 0
  skiplimit: any;
  contentLoaded: boolean = false;
  logoNodata = 'assets/alien.png';
  @Output() valueUpdated = new EventEmitter<Product[]>();
  deviceInfo!: any;
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktopDevice: boolean = false;
  categoryDisplay: string = "";
  categoryDisplayIcon: string = "";
  isFilter: boolean = false;;
  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public cookie: CookieService,
    private productService: ProductService,
    public breakpointObserver: BreakpointObserver,
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
    private deviceService: DeviceDetectorService,
    // private analytics: AngularFireAnalytics
  ) {
    this.epicFunction();
    this.productCategory = activatedRoute.snapshot.params.category;
    this.productSource = activatedRoute.snapshot.params.sourceName;
    /*this.products$ = this.store.select(selectItems).pipe(
      tap(res => {

        if(res){
          this.dataSourceScroll = new CdkVirtualScrollViewportComponent(res, res.length, 20)
        }
      })
    );*/
  }

  ngOnInit(): void {
    console.log("hello 1");

    this.canonicalService.setCanonicalURL();
    this.title.setTitle("Les meilleures offres & bons plans, soldes, promotion, réduction, liquidation en ligne et en magasin » promodeep - promodeep.com");
    this.metaTagService.addTag(
      { name: "description", content: "➤ PromoDeep cherche et partage les meilleures offres et codes promotions, les meilleures deals, meilleurs prix, soldes et réductions" },
    );
    this.breakpointObserver
      .observe(['(min-width: 791px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobile = false;
        } else {
          this.mobile = true;
        }
      });
    this.products = [];
    let data = {
      limit: this.limit,
      skip: this.skip
    }

    if (this.products.length == 0) {
      console.log("hello 3");
      // this.productUrl = this.cookie.getCookie('productUrl');
      // this.href = this.router.url;

      ////////////product by category //////////////////////

      // this.canonicalService.setCanonicalURL();
      // if (this.productCategory === pathRoutingEnum.dashboard.valueOf()) {
      //   this.canonicalService.setCanonicalURL();
      //   this.title.setTitle(this.textTitlByCategory("A LA UNE"));
      //   this.metaTagService.updateTag(
      //     { name: "description", content: this.textDescriptionParCategory("A LA UNE") },
      //   );
      // }
      if (this.productCategory != null) {

        this.canonicalService.setCanonicalURL();
        if (this.productCategory === categoryEnum.electro) {
          this.title.setTitle(this.textTitlByCategory("High-tech"));
          this.categoryDisplay = "High-tech";
          this.categoryDisplayIcon = "desktop_windows";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.videoGame) {
          this.title.setTitle(this.textTitlByCategory("Consoles et Jeux videos"));
          this.categoryDisplay = "Consoles et Jeux videos";
          this.categoryDisplayIcon = "videogame_asset";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.fashion) {
          this.title.setTitle(this.textTitlByCategory("Mode & Accessoires"));
          this.categoryDisplay = "Mode & Accessoires";
          this.categoryDisplayIcon = "local_mall";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.cosmetic) {
          this.title.setTitle(this.textTitlByCategory("Cosmétique & Bien être"));
          this.categoryDisplay = "Cosmétique & Bien être";
          this.categoryDisplayIcon = "spa";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.sport) {
          this.title.setTitle(this.textTitlByCategory("Sport"));
          this.categoryDisplay = "Sport";
          this.categoryDisplayIcon = "fitness_center";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.family) {
          this.title.setTitle(this.textTitlByCategory("Enfant & bébé"));
          this.categoryDisplay = "Enfant & bébé";
          this.categoryDisplayIcon = "child_friendly";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.home) {
          this.title.setTitle(this.textTitlByCategory("Maison & Habitat"));
          this.categoryDisplay = "Maison & Habitat";
          this.categoryDisplayIcon = "event_seat";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.animal) {
          this.title.setTitle(this.textTitlByCategory("Animaux domestiques de compagnie"));
          this.categoryDisplay = "Animaux domestiques de compagnie";
          this.categoryDisplayIcon = "pets";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }

        // if (this.productCategory === categoryEnum.trip) {
        //   this.title.setTitle(this.textTitlByCategory("Voyages"));
        //   this.categoryDisplay = "Voyages";
        //   this.categoryDisplayIcon = "airplanemode_active";
        //   this.metaTagService.updateTag(
        //     { name: "description", content: this.textDescriptionParCategory("Voyages") },
        //   );
        // }
        if (this.productCategory === categoryEnum.mechanic) {
          this.title.setTitle(this.textTitlByCategory("Auto Moto"));
          this.categoryDisplay = "Auto Moto";
          this.categoryDisplayIcon = "directions_car";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.gardenDIY) {
          this.title.setTitle(this.textTitlByCategory("Jardin & Bricolage"));
          this.categoryDisplay = "Jardin & Bricolage";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.service) {
          this.title.setTitle(this.textTitlByCategory("Services"));
          this.categoryDisplay = "Services";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.featuredBrand) {
          this.title.setTitle(this.textTitlByCategory("Vedette Brands"));
          this.categoryDisplay = "Vedette Brands";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.training) {
          this.title.setTitle(this.textTitlByCategory("Cultures & formations"));
          this.categoryDisplay = "Cultures & formations";
          this.categoryDisplayIcon = "school";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.deal) {
          this.title.setTitle(this.textTitlByCategory("Forfaits"));
          this.categoryDisplay = "Forfaits";
          this.categoryDisplayIcon = "settings_phone";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.finance) {
          this.title.setTitle(this.textTitlByCategory("Banques, Finances & Assurances"));
          this.categoryDisplay = "Banques & Assurances";
          this.categoryDisplayIcon = "account_balance";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.grocery) {
          this.title.setTitle(this.textTitlByCategory("Courses & Épicerie"));
          this.categoryDisplay = "Courses & Épicerie";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.crypto) {
          this.title.setTitle(this.textTitlByCategory("Crypto"));
          this.categoryDisplay = "Crypto";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        if (this.productCategory === categoryEnum.free) {
          this.title.setTitle(this.textTitlByCategory("Gratuit"));
          this.categoryDisplay = "Gratuit";
          this.metaTagService.updateTag(
            { name: "description", content: this.textDescriptionParCategory(this.categoryDisplay) },
          );
        }
        // this.productService.categoryProductsFilter(this.productCategory, data).subscribe(data => {
        //   this.products = data;

        // });
        let category = this.productCategory;
        this.store.dispatch(new FilterCategoryAction({ category, data }));
      }

      ////////////product by source //////////////////////
      else if (this.productSource != null) {
        this.canonicalService.setCanonicalURL();
        this.title.setTitle("Les meilleures offres & code promotions chez " + this.productSource + " - promodeep.com");
        this.metaTagService.updateTag(
          { name: "description", content: "➤ " + this.productSource + ": PromoDeep cherche et partage les meilleures offres et codes promotions, meilleurs prix, soldes et réductions de " + this.productSource },
        );
        // this.productService.getSourceProductsFilter(this.productSource, data).subscribe(data => {
        //   this.products = data;
        // });
        let source = this.productSource;
        console.log("8888888888888888888Walid");
        console.log(source);

        this.store.dispatch(new FilterBySourceAction({ source, data }));

        ////////////product by dashboard //////////////////////
      } else if (this.router.url === pathRoutingEnum.dashboard.valueOf() || this.router.url === pathRoutingEnum.promotion.valueOf()) {
        this.canonicalService.setCanonicalURL();
        this.title.setTitle("Les meilleures offres & bons plans, soldes, promotion, réduction, liquidation en ligne et en magasin » promodeep - promodeep.com");
        this.metaTagService.updateTag(
          { name: "description", content: "➤ PromoDeep cherche et partage les meilleures offres et codes promotions, les meilleures deals, meilleurs prix, soldes et réductions" },
        );
        // this.productService.getCustomProducts(data).subscribe(data => {
        //   this.products = data;


        // });

        this.store.dispatch(new GetAllProductsAction(data));
        ////////////product by promotionCode //////////////////////
      } else if (this.router.url === pathRoutingEnum.promotionCode.valueOf()) {
        this.canonicalService.setCanonicalURL();
        this.title.setTitle("Les codes promotions, Codes promo, Codes promotionnels, Coupons, Codes de coupon, Rabais - promodeep.com");
        this.metaTagService.updateTag(
          { name: "description", content: "PromoDeep cherche et partage les code promotions, Codes promo, Codes promotionnels, Coupons, Codes de coupon, Rabais - promodeep.com" },
        );


        this.store.dispatch(new FilterByPromotionCodeAction(data));

        // this.productService.getPromotionCodeProductsFilter(data).subscribe(data => {
        //   this.products = data;

        // });
      } else {
        this.canonicalService.setCanonicalURL();
        this.title.setTitle("Les meilleures offres & bons plans, soldes, promotion, réduction, liquidation en ligne et en magasin » promodeep - promodeep.com");
        this.metaTagService.updateTag(
          { name: "description", content: "➤ PromoDeep cherche et partage les meilleures offres et codes promotions, les meilleures deals, meilleurs prix, soldes et réductions" },
        );
        // this.productService.getCustomProducts(data).subscribe(data => {
        //   this.products = data;


        // });

        this.store.dispatch(new GetAllProductsAction(data));
      }

      if (this.products.length == 0) {
        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      }


      this.store.subscribe((myStore) => {
        if (myStore.ProductsStateFromModule) {

          this.state = myStore.ProductsStateFromModule;
          if (this.state?.products != undefined && this.state?.products.length > 0) {

            this.products = this.state?.products;
            //const productsShuffle = this.shuffle([...this.products]);
            //this.products = this.shufflingArray([...this.products]);
            // this.products = this.shuffle([...this.products]);
            // this.products = this.shuffle([...this.products]);
          } else {
            this.products = [];
          }
        }
      });
    }
  }

  shuffle(array: any) {
    for (var i = array.length; i > 0; --i)
      array.push(array.splice(Math.random() * i | 0, 1)[0]);
    return array;
  }

  shufflingArray(value: any[]) {

    let items: any[] = [];

    for (let i = 0; i < value.length; i++) {
      items.push(value[i]);
    }

    // reversing items array
    for (let i = items.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [items[i], items[j]] = [items[j], items[i]];
    }
    return items;
  }

  textTitlByCategory(productCategory: string) {
    return "Les meilleures offres & code promotions " + productCategory + " - promodeep.com"
  }
  textDescriptionParCategory(productCategory: string) {
    return "➤ " + productCategory + ": PromoDeep cherche et partage les meilleures offres et codes promotions, les meilleures deals, meilleurs prix, soldes et réductions - promodeep.com"
  }

  onSortChange(event: any) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  onSearch(dataForm: any) {
    let data = {
      limit: this.limit,
      skip: this.skip
    }
    if (this.router.url === pathRoutingEnum.promotionCode.valueOf()) {
      this.store.dispatch(new SearchProductsByPromotionCodeAction(dataForm.searchKeyWords));
      // this.productService.searchProductsByPromotionCode(dataForm.searchKeyWords, data).subscribe(data => {
      //   this.products = data;
      // });


    } else {
      this.store.dispatch(new SearchProductsAction(dataForm.searchKeyWords))
      // this.productService.searchProducts(dataForm, data).subscribe(data => {
      //   this.products = data;
      //   this.valueUpdated.next(data)
      // });

    }
  }



  // onSearchByCategory(dataForm: any) {

  //   let data = {
  //     limit: this.limit,
  //     skip: this.skip
  //   }
  //   this.productDto = {
  //     category: this.productCategory,
  //     searchKeyWords: dataForm.searchKeyWords,
  //     source: dataForm.source
  //   };
  //   //this.store.dispatch(new SearchProductsByCategoryAction(this.productDto));


  //   this.productService.searchProductsByCategory(this.productDto, data).subscribe(data => {
  //     this.products = data;


  //   });

  // }

  onSearchBySource(dataForm: any) {

    let data = {
      limit: this.limit,
      skip: this.skip
    }
    this.productDto = {
      category: this.productCategory,
      searchKeyWords: dataForm.searchKeyWords,
      source: this.productSource
    };
    this.store.dispatch(new SearchProductsBySourceAction(this.productDto));

    // this.productService.searchProductsBySource(this.productDto, data).subscribe(data => {
    //   this.products = data;


    // });

  }

  onSearchByPromotionCode(dataForm: any) {

    this.productDto = {
      category: this.productCategory,
      searchKeyWords: dataForm.searchKeyWords,
      source: this.productSource
    };
    this.store.dispatch(new SearchProductsByPromotionCodeAction(this.productDto));


  }




  onDetails(p: Product, _i: number) {
    console.log(p._id);
    if (p._id) {
      this.router.navigateByUrl(pathRoutingEnum.product.valueOf() + '/' + p._id);
    }
    console.log(p.merchant_id);
    if (p._id== undefined && p.merchant_id) {
      this.router.navigateByUrl(pathRoutingEnum.product.valueOf() + '/' + p.merchant_id);
    }
  }

  calculateDiffAddDate(dateSent: Date) {
    this.diffAddDate = this.timeCalculator.calculateDiffAddDate(dateSent);
    return this.diffAddDate;
  }

  monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juiller", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"
  ];

  calculateDiffEndDate(product: Product) {
    this.dealBloced = false;
    this.dealWillBloced = false;
    if (product.selected) {
      this.diffEndDate = this.timeCalculator.calculateDiffEndDate(product);
      if (this.diffEndDate.includes("Expiré")) {
        this.dealBloced = true;
      }
      if (this.diffEndDate.includes("Expire Bientôt")) {
        this.dealWillBloced = true;
      }
    }
    return this.diffEndDate;
  }

  onFilterBySource(productSource: any) {

    this.redirectTo(pathRoutingEnum.source.valueOf() + '/' + productSource);
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  @HostListener("window:scroll")
  onWindowScroll() {


    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = false;
    }
    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }
  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  scrollToFooter() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  incrementViewNumberProduct(product: Product) {
    // this.analytics.logEvent('subscribed')
    this.viewNumber = product.viewNumber;
    this.product = { ...product, viewNumber: product.viewNumber + 1 }
    this.productService.updateProduct(this.product).subscribe();
    const arrayOfObjects = [...this.products]
    const index = arrayOfObjects.findIndex(item => item._id === this.product._id)
    arrayOfObjects[index] = this.product;
    this.products = [...arrayOfObjects];
    // this.cookie.setCookie({
    //   productUrl: "productUrl",
    //   value: product.affiliateUrl,
    //   session: true,
    // });
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    this.codePromotionCopied = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copieCodePromotion = true;
  }

  onPromotionCodeDisplay(product: Product) {
    this.copieCodePromotion = false;
    this.promotionCodeDisplay = true;
    this.codePromotionCopied = product.promotionCode;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(_e: any) {


    if (this.onScrollTopPageYOffset < window.pageYOffset) { this.onScrollTopPageYOffset = window.pageYOffset }
    if (this.bindingDataPageYOffset + 1000 < window.pageYOffset) {
      this.bindingDataPageYOffset = window.pageYOffset;
    }
    if (this.bindingDataPageYOffset - 1000 > window.pageYOffset) {
      this.bindingDataPageYOffset = window.pageYOffset;
    }

    if (this.isGetProducts && this.products.length == this.skip || this.products.length == this.skip + 10) {
      if (this.products.length > 0) {
        this.limit = this.limit;
        this.skip = this.skip + 10;
        if (this.products.length <= this.skip) {
          this.skiplimit = {
            limit: this.limit,
            skip: this.skip
          }


          if (this.products.length + 10 == this.skip || 10 == this.skip) {
            ////////////product by category //////////////////////
            if (this.productCategory != null) {
              this.productService.categoryProductsFilter(this.productCategory, this.skiplimit).subscribe(data => {
                this.getSumData(data);
              });
              // let category = this.productCategory;
              // let skiplimit = this.skiplimit;
              // this.store.dispatch(new FilterCategoryAction({category, skiplimit}));
            }

            ////////////product by source //////////////////////
            else if (this.productSource != null) {
              this.productService.getSourceProductsFilter(this.productSource, this.skiplimit).subscribe(data => {
                this.getSumData(data);
              });
              // let source = this.productSource;
              // let skiplimit = this.skiplimit;
              // this.store.dispatch(new FilterBySourceAction({source, skiplimit}));

              ////////////product by dashboard //////////////////////
            } else if (this.router.url === pathRoutingEnum.dashboard.valueOf() || this.router.url === pathRoutingEnum.promotion.valueOf()) {
              this.productService.getCustomProducts(this.skiplimit).subscribe(data => {
                this.getSumData(data);
              });
              // let skiplimit = this.skiplimit;
              // this.store.dispatch(new GetAllProductsAction(skiplimit));
              ////////////product by promotionCode //////////////////////
            } else if (this.router.url === pathRoutingEnum.promotionCode.valueOf()) {
              this.productService.getPromotionCodeProductsFilter(this.skiplimit).subscribe(data => {
                this.getSumData(data);
              });
              // let skiplimit = this.skiplimit;
              // this.store.dispatch(new FilterByPromotionCodeAction(skiplimit));
            }
          }

        }
      }
    }


  }

  getSumData(data: any) {
    this.productExtention = data;
    if (this.productExtention.length == 0) {
      this.isGetProducts = false;
      return;
    }
    if (this.productExtention.length > 0 && this.products) {
      this.isGetProducts = true
      for (let i = 0; i < this.productExtention.length; i++) {
        this.product = this.productExtention[i];
        this.products = [...this.products, this.product]


      }
    }
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  isFilterChange() {
    this.isFilter = !this.isFilter;
  }

}





