<div *ngIf="categoryDisplay" class="container" style="padding-right: 0px; padding-left: 0px;">
  <nav mat-tab-nav-bar class="ms-nav-list-scroll" disablePagination="true" mat-align-tabs="center"
    backgroundColor="backgroundThemeColor">
    <mat-toolbar style="background-color: aliceblue;">
      <div>
        <mat-icon style="margin-right: 10px; vertical-align: sub;">{{categoryDisplayIcon}}</mat-icon>
        <span>{{categoryDisplay}}</span>

      </div>
      <!-- <span class="example-spacer"></span>

      <div *ngIf="!isDesktopDevice || mobile">
        <button *ngIf="!isFilter" (click)="isFilterChange()" mat-button><mat-icon>shopping_cart</mat-icon></button>
        <button *ngIf="isFilter" (click)="isFilterChange()" mat-button>
          <mat-icon>loyalty</mat-icon></button>
      </div>

      <div *ngIf="isFilter && (isDesktopDevice || !mobile)">
        <button mat-button><mat-icon>shopping_cart</mat-icon> Promotions</button>
        <span *ngIf="isDesktopDevice || !mobile">|</span>
        <button mat-button>
          <mat-icon>loyalty</mat-icon> Codes Promo</button>
      </div>

      <span *ngIf="isDesktopDevice || !mobile" (click)="isFilterChange()" [routerLinkActive]="'activeItem'">
        <mat-icon>filter_list</mat-icon>
      </span> -->
    </mat-toolbar>
  </nav>
  <br>
</div>


<div *ngIf="products.length == 0 && contentLoaded == false" class="container">
  <div class="card card-padding">
    <div class="fb-item">
      <div class="first-section-wrapper">

        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                    width: '120px',
                    height: '120px',
                    'border-radius': '10px'
                  }">
        </ngx-skeleton-loader>

        <div class="gravatar-title">
          <div>
            <ngx-skeleton-loader [theme]="{
                      width: '90%',
                      'border-radius': '0',
                      'height': '25px',
                      'margin-top': '15px',
                      'margin-bottom': '10px',
                      'border-bottom': '2px solid rgba(0, 0, 0, 0.05)'
                    }"></ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '90%', 'border-radius': '0', height: '25px' }">
            </ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '90%', 'border-radius': '0', height: '25px' }">
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="gravatar-title-detail">
          <div>
            <ngx-skeleton-loader [theme]="{
                      width: '100%',
                      'border-radius': '0',
                      height: '45px',
                      'margin-bottom': '10px'
                    }"></ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '45px' }">
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-padding" style="margin-top:5px">
    <div class="fb-item">
      <div class="first-section-wrapper">

        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                    width: '120px',
                    height: '120px',
                    'border-radius': '10px'
                  }">
        </ngx-skeleton-loader>

        <div class="gravatar-title">
          <div>
            <ngx-skeleton-loader [theme]="{
                      width: '90%',
                      'border-radius': '0',
                      'height': '25px',
                      'margin-top': '15px',
                      'margin-bottom': '10px',
                      'border-bottom': '2px solid rgba(0, 0, 0, 0.05)'
                    }"></ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '90%', 'border-radius': '0', height: '25px' }">
            </ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '90%', 'border-radius': '0', height: '25px' }">
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="gravatar-title-detail">
          <div>
            <ngx-skeleton-loader [theme]="{
                      width: '100%',
                      'border-radius': '0',
                      height: '45px',
                      'margin-bottom': '10px'
                    }"></ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '45px' }">
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-padding" style="margin-top:5px">
    <div class="fb-item">
      <div class="first-section-wrapper">

        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                    width: '120px',
                    height: '120px',
                    'border-radius': '10px'
                  }">
        </ngx-skeleton-loader>

        <div class="gravatar-title">
          <div>
            <ngx-skeleton-loader [theme]="{
                      width: '90%',
                      'border-radius': '0',
                      'height': '25px',
                      'margin-top': '15px',
                      'margin-bottom': '10px',
                      'border-bottom': '2px solid rgba(0, 0, 0, 0.05)'
                    }"></ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '90%', 'border-radius': '0', height: '25px' }">
            </ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '90%', 'border-radius': '0', height: '25px' }">
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="gravatar-title-detail">
          <div>
            <ngx-skeleton-loader [theme]="{
                      width: '100%',
                      'border-radius': '0',
                      height: '45px',
                      'margin-bottom': '10px'
                    }"></ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '45px' }">
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-padding" style="margin-top:5px">
    <div class="fb-item">
      <div class="first-section-wrapper">

        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                    width: '120px',
                    height: '120px',
                    'border-radius': '10px'
                  }">
        </ngx-skeleton-loader>

        <div class="gravatar-title">
          <div>
            <ngx-skeleton-loader [theme]="{
                      width: '90%',
                      'border-radius': '0',
                      'height': '25px',
                      'margin-top': '15px',
                      'margin-bottom': '10px',
                      'border-bottom': '2px solid rgba(0, 0, 0, 0.05)'
                    }"></ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '90%', 'border-radius': '0', height: '25px' }">
            </ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '90%', 'border-radius': '0', height: '25px' }">
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="gravatar-title-detail">
          <div>
            <ngx-skeleton-loader [theme]="{
                      width: '100%',
                      'border-radius': '0',
                      height: '45px',
                      'margin-bottom': '10px'
                    }"></ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '45px' }">
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="products.length == 0 && contentLoaded">
  <div class="card-div">
    <div class="no-data">
      <br>
      <br>
      <br>
      <img [src]="logoNodata" width="115" height="148" [alt]="logoNodata" />
      <p>
        Il n'y a actuellement aucun contenu à présenter <mat-icon>mood_bad</mat-icon>. Vous pouvez explorer différentes
        termes de recherche pour trouver ce que vous cherchez !
      </p>
      <br>
      <br>
      <br>
      <br>
    </div>
  </div>
</div>

<div *ngIf="products.length > 0" (scroll)="onScroll($event)" class="container"
  [ngClass]="products.length == 1 ? 'container-one-deal' : 'container-many-deal' ">
  <!-- <mat-toolbar style="background-color:rgb(255, 255, 255)">
    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between" *ngIf="productCategory">
      <form #f="ngForm">
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
          <input ngModel (input)="onSearchByCategory(f.value)" name='searchKeyWords' type="search" class="form-control"
            placeholder="Recherche ici">
        </span>
      </form>

    </div>

    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between" *ngIf="productSource">
      <form #f="ngForm">
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
          <input ngModel (input)="onSearchBySource(f.value)" name='searchKeyWords' type="search" pInputText
            placeholder="Recherche ici">
        </span>
      </form>

    </div>
    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between" *ngIf="!productCategory && !productSource">
      <form #f="ngForm">
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
          <input ngModel (input)="onSearch(f.value)" name='searchKeyWords' type="search" pInputText
            placeholder="Recherche ici">
        </span>
      </form>
    </div>
  </mat-toolbar> -->


  <div>
    <div>
      <ng-container>

        <div *ngFor="let product of products; let i = index">
          <div class="card-div" *ngIf="product.available">
            <span hidden><mat-icon *ngIf="product.expirationDate">hourglass_empty</mat-icon>
              {{calculateDiffEndDate(product)}}</span>
            <div class="p-col-12">
              <div class="product-list-item" [class.disabled-contenct]="!product.available">
                <div class="demoWrapper" [ngClass]="isDesktopDevice ===true ? 'demoWrapper' : 'demoWrapperMobile'">
                  <div class="container-img">
                    <img ngOptimizedImage (click)="onDetails(product, i)" *ngIf="product.pictureUrl;squarePromotionPicture"
                      [alt]="product.pictureUrl"
                      [ngClass]="[dealBloced ===true ? 'product-img-expired' : 'product-img', product.selected ===false ? 'product-img-expired' : 'product-img'] "
                      src={{product.pictureUrl}}>
                    <div>
                      <span *ngIf="dealBloced ===true || product.selected ===false"
                        style="background-color: red;">Expiré</span>
                      <span *ngIf="dealWillBloced ===true" style="color: rgb(255 106 0); white-space: nowrap;"><mat-icon
                          *ngIf="product.expirationDate">hourglass_empty</mat-icon>{{diffEndDate}}</span>
                    </div>


                  </div>

                </div>
                <!-- <div *ngIf="product.partner === 'Amazon'">
                  <div [innerHTML]="product.pictureUrl" style="width: 100%;"></div>
                </div> -->
                <div class="product-list-detail" *ngIf="isDesktopDevice">
                  <div class="toast-header-custom">
                    <a routerLink="/"
                      [ngClass]="[dealBloced ===true ? 'see-source-expired' : 'see-source', product.selected ===false ? 'see-source-expired' : 'see-source' ]"
                      (click)="onFilterBySource(product.sourceName)">
                      {{product.sourceName}}</a>
                    <!-- {{product.expirationDate | date: 'dd/MM/yy'}}</span> -->
                    <!-- <span *ngIf="product.expirationDate && product.addedDate && !mobile" class="thread-divider">|</span>

                       <span *ngIf="product.addedDate && !mobile"> <mat-icon>history</mat-icon>
                        {{calculateDiffAddDate(product.addedDate)}}</span> -->
                    <strong class="me-auto"></strong>

                    <span *ngIf="product.percentageDiscount"
                      [ngClass]="[dealBloced ===true ? 'percentageDiscount-expired' : 'percentageDiscount', product.selected ===false ? 'percentageDiscount-expired' : 'percentageDiscount' ]">-{{product.percentageDiscount}}%</span>
                  </div>
                  <div class="toast-body">
                    <a routerLink="/" class="mat-menu-item"
                      [ngClass]="[dealBloced ===true ? 'product-name-expired' : 'product-name', product.selected ===false ? 'product-name-expired' : 'product-name'] "
                      (click)="onDetails(product, i)">{{product.name}}</a>
                    <span
                      [ngClass]="[dealBloced ===true ? 'product-price-expired' : 'product-price', product.selected ===false ? 'product-price-expired' : 'product-price' ]"
                      *ngIf="product.priceDiscount">{{product.priceDiscount}}€</span>
                    <span
                      [ngClass]="[dealBloced ===true ? 'oldPrice-expired' : 'oldPrice', product.selected ===false ? 'oldPrice-expired' : 'oldPrice' ]"
                      *ngIf="product.price && product.price != product.priceDiscount" class="product-price">{{product.price  | number : '1.2-2'}}€</span>

                    <div>
                      <span title="description" (click)="onDetails(product)"
                        [ngClass]="[dealBloced ===true ? 'description-expired' : 'description', product.selected ===false ? 'description-expired' : 'description' ]"
                        [innerHTML]="product.description | slice:0:300"></span>
                      <span *ngIf="product.description && product.description.length > 30"></span>
                    </div>
                  </div>
                </div>

                <div class="product-list-action" *ngIf="products != undefined && isDesktopDevice">
                  <button mat-button color="primary"
                    *ngIf="product.promotionCode && products[i].promotionCode != codePromotionCopied"
                    (click)="onPromotionCodeDisplay(product)" type="button" icon="bi bi-scissors"
                    class="btn btn position-relative promotion-code mat-select-value"
                    [ngClass]="[dealBloced ===true ? 'mat-stroked-button-promocode-expired' : '', product.selected ===false ? 'mat-stroked-button-promocode-expired' : '' ]">Voir
                    Code Promo
                  </button>

                  <button type="button" mat-button color="primary"
                    *ngIf="product.promotionCode && copieCodePromotion===false && products[i].promotionCode === codePromotionCopied &&  promotionCodeDisplay"
                    class="btn btn position-relative promotion-code" (click)="copyMessage(product.promotionCode)"
                    [ngClass]="[dealBloced ===true ? 'mat-stroked-button-promocode-expired' : '', product.selected ===false ? 'mat-stroked-button-promocode-expired' : '' ]">
                    <!-- <img [src]="logoScissors" width="20" height="22" [alt]="logoScissors" /> -->

                    {{products[i].promotionCode}}
                    <mat-icon>content_copy</mat-icon>
                  </button>

                  <button type="button" mat-button color="primary"
                    *ngIf="product.promotionCode && copieCodePromotion && products[i].promotionCode === codePromotionCopied &&  promotionCodeDisplay"
                    class="btn btn position-relative promotion-code" (click)="copyMessage(product.promotionCode)"
                    [ngClass]="[dealBloced ===true ? 'mat-stroked-button-promocode-expired' : '', product.selected ===false ? 'mat-stroked-button-promocode-expired' : '' ]">
                    {{product.promotionCode}}
                    <span *ngIf="products[i].promotionCode===codePromotionCopied"
                      class="badge rounded-pill bg-light text-dark">copié
                    </span>
                  </button>
                  <!-- <a pButton class="href" (click)="incrementViewNumberProduct(product)" class="affiliateUrlBtn"
                    href="{{product.affiliateUrl}}" icon="pi pi-shopping-cart" label="Voir le deal" target="_blank"></a> -->

                  <a type="button" class="btn btn position-relative" href="{{product.affiliateUrl}}" target="_blank"
                    [ngClass]="[dealBloced ===true ? 'mat-stroked-button-expired' : 'mat-stroked-button', product.selected ===false ? 'mat-stroked-button-expired' : 'mat-stroked-button' ]"
                    (click)="incrementViewNumberProduct(product)">
                    <!-- <mat-icon matBadge={{product.viewNumber}} matBadgeColor="warn"
                  *ngIf="product.viewNumber >= 0 && product.viewNumber <= 999"
                  [ngStyle]="{'color':'white', 'vertical-align': 'middle'}">visibility</mat-icon>
                <mat-icon matBadge="999" matBadgeColor="warn" *ngIf="product.viewNumber > 999"
                  [ngStyle]="{'color':'white'}">visibility</mat-icon> -->
                    <span class="button-text">Voir l'offre</span>
                  </a>

                </div>


                <div class="product-list-detail-mobile" *ngIf="!isDesktopDevice">
                  <div class="toast-header-custom">
                    <a routerLink="/"
                      [ngClass]="[dealBloced ===true ? 'see-source-expired' : 'see-source', product.selected ===false ? 'see-source-expired' : 'see-source' ]"
                      (click)="onFilterBySource(product.source)">
                      {{product.source}}</a>
                    <!-- <span *ngIf="product.expirationDate && product.addedDate && !mobile" class="thread-divider">|</span>

                       <span *ngIf="product.addedDate && !mobile"> <mat-icon>history</mat-icon>
                        {{calculateDiffAddDate(product.addedDate)}}</span> -->
                    <strong class="me-auto"></strong>

                    <span *ngIf="product.percentageDiscount"
                      [ngClass]="[dealBloced ===true ? 'percentageDiscount-expired' : 'percentageDiscount', product.selected ===false ? 'percentageDiscount-expired' : 'percentageDiscount' ]">-{{product.percentageDiscount}}%</span>
                  </div>
                  <div class="toast-body" *ngIf="!isDesktopDevice || mobile">
                    <div class="mat-grid-list">

                      <div class="mat-grid-tile">
                        <div>
                          <a routerLink="/" class="mat-menu-item"
                            [ngClass]="[dealBloced ===true ? 'product-name-expired' : 'product-name', product.selected ===false ? 'product-name-expired' : 'product-name' ]"
                            (click)="onDetails(product, i)">{{product.name}}</a>
                          <span *ngIf="product.priceDiscount && products[i].promotionCode!= undefined && products[i].promotionCode != codePromotionCopied"
                            [ngClass]="[dealBloced ===true ? 'product-price-expired' : 'product-price', product.selected ===false ? 'product-price-expired' : 'product-price' ]">{{product.priceDiscount}}€</span>
                          <span *ngIf="product.price && product.price != product.priceDiscount && products[i].promotionCode!= undefined && products[i].promotionCode != codePromotionCopied"
                            [ngClass]="[dealBloced ===true ? 'oldPrice-expired' : 'oldPrice', product.selected ===false ? 'oldPrice-expired' : 'oldPrice' ]">{{product.price  | number : '1.2-2' }}€</span>


                          <button type="button" mat-button color="primary"
                            *ngIf="copieCodePromotion===false && products[i].promotionCode === codePromotionCopied &&  promotionCodeDisplay"
                            class="btn btn position-relative promotion-code"
                            (click)="copyMessage(product.promotionCode)">
                            <!-- <img [src]="logoScissors" width="20" height="22" [alt]="logoScissors" /> -->
                            {{products[i].promotionCode}}
                            <mat-icon>content_copy</mat-icon>
                          </button>

                          <button type="button" mat-button color="primary"
                            *ngIf="copieCodePromotion && products[i].promotionCode === codePromotionCopied &&  promotionCodeDisplay"
                            class="btn btn position-relative promotion-code"
                            (click)="copyMessage(product.promotionCode)">
                            {{product.promotionCode}}
                            <span *ngIf="products[i].promotionCode===codePromotionCopied"
                              class="badge rounded-pill bg-light text-dark">copié
                            </span>
                          </button>
                        </div>
                        <!-- <a (click)="onDetails(product)" class="product-description">
                        {{product.description | slice:0:30}} <span *ngIf="product.description.length > 30">...</span>
                      </a> -->
                      </div>
                      <div class="mat-grid-tile_2" *ngIf="products != undefined && !isDesktopDevice || mobile">
                        <div class="product-list-action-mobile">

                          <a aria-label="source" href="{{product.affiliateUrl}}" target="_blank"
                            [routerLinkActive]="'activeItem'">
                            <mat-icon
                              [ngClass]="[dealBloced ===true ? 'mobile-affiliate-button-expired' : 'mobile-affiliate-button', product.selected ===false ? 'mobile-affiliate-button-expired' : 'mobile-affiliate-button' ]">launch</mat-icon>
                          </a>

                          <span *ngIf="product.promotionCode && products[i].promotionCode != codePromotionCopied"
                            (click)="onPromotionCodeDisplay(product)" type="button" icon="bi bi-scissors"
                            class="scissors">
                            <mat-icon
                              [ngClass]="[dealBloced ===true ? 'mobile-affiliate-button-expired' : 'mobile-affiliate-button', product.selected ===false ? 'mobile-affiliate-button-expired' : 'mobile-affiliate-button' ]">content_cut</mat-icon>
                          </span>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>



              </div>
            </div>
            <hr class="dropdown-divider">
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
