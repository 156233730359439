import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-camera-comprator',
  templateUrl: './camera-comprator.component.html',
  styleUrls: ['./camera-comprator.component.scss']
})
export class CameraCompratorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
