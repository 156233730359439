import { CameraCompratorComponent } from './componenets/comprator/products-comprator/camera/camera-comprator/camera-comprator.component';
import { DialogContentInscription } from './componenets/header-sticky/inscription-dialog/dialog-content-inscription';
import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './componenets/dashboard/dashboard.component';
import { DatePipe } from '@angular/common';
import { PromotionCodeComponent } from './componenets/dashboard/promotion-code/promotion-code.component';
import { HeaderStickyComponent } from './componenets/header-sticky/header-sticky.component';
import { LegalNoticeComponent } from './componenets/legal-notice/legal-notice.component';
import { AboutComponent } from './componenets/about/about.component';
import { PartnerComponent } from './componenets/partner/partner.component';
import { CookiesPolicyComponent } from './componenets/cookies-policy/cookies-policy.component';
import { PrivacyPolicyComponent } from './componenets/privacy-policy/privacy-policy.component';
import { ContactComponent } from './componenets/contact-ngrx/contact/contact.component';
import { CookieService } from './services/cookie.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HeaderStickyFrontOfficeComponent } from './componenets/header-sticky/header-sticky-front-office/header-sticky-front-office.component';
import { PublicHomeComponent } from './componenets/public-home/public-home.component';
import { NavBarFooterStikyComponent } from './footer/nav-bar-footer-stiky/nav-bar-footer-stiky.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { GeneralPolicyComponent } from './componenets/general-policy/general-policy.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ProductBySourceComponent } from './componenets/producs-ngrx/product-by-source/product-by-source.component';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatDividerModule} from '@angular/material/divider';
import { CookieDialogComponent } from './componenets/cookie-dialog/cookie-dialog.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DialogContentExampleDialog } from './componenets/cookie-dialog/dialog-content/dialog-content-example-dialog';
import { ProductDetailsNgrxComponent } from './componenets/producs-ngrx/product-details-ngrx/product-details-ngrx.component';
import { NavBarFooterMobileComponent } from './footer/nav-bar-footer-mobile/nav-bar-footer-mobile.component';
import { MenuMobileComponent } from './footer/menu-mobile/menu-mobile.component';
import {MatChipsModule} from '@angular/material/chips';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { productsReducer } from './ngrx/products.reducer';
import { ProductsEffects } from './ngrx/products.effects';
import { sourcesReducer } from './ngrx/source/sources.reducer';
import { SourcesEffects } from './ngrx/source/sources.effects';
import { contactsReducer } from './ngrx/contact/contacts.reducer';
import { ContactsEffects } from './ngrx/contact/contacts.effects';
import { settingsReducer } from './ngrx/setting/settings.reducer';
import { SettingsEffects } from './ngrx/setting/settings.effects';
import { newslettersReducer } from './ngrx/newsletter/newsletter.reducer';
import { NewslettersEffects } from './ngrx/newsletter/newsletter.effects';
import { A11yModule } from '@angular/cdk/a11y';
import { PromptComponent } from './componenets/prompt/prompt.component';
import { MatCardModule } from '@angular/material/card';
import { DialogContentNewsLetter } from './componenets/newsletter-dialog/dialog-content-newsletter';
import { DialogContentLogin } from './componenets/header-sticky/login-dialog/dialog-content-login';
import { DialogContentNewsLetterDisable } from './componenets/newsletter-dialog-disable/dialog-content-newsletter-disable';
import { jwtInterceptorProvider } from './shared/interceptors/jwt.interceptor';
import { ErrorInterceptor, errorInterceptorProvider } from './shared/interceptors/error.interceptor';
//const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    DashboardComponent,
    ProductDetailsNgrxComponent,
    ProductBySourceComponent,
    PromotionCodeComponent,
    HeaderStickyComponent,
    ContactComponent,
    LegalNoticeComponent,
    AboutComponent,
    PartnerComponent,
    CookiesPolicyComponent,
    PrivacyPolicyComponent,
    HeaderStickyFrontOfficeComponent,
    PublicHomeComponent,
    NavBarFooterStikyComponent,
    GeneralPolicyComponent,
    CookieDialogComponent,
    DialogContentExampleDialog,
    DialogContentNewsLetter,
    DialogContentLogin,
    DialogContentInscription,
    DialogContentNewsLetterDisable,
    NavBarFooterMobileComponent,
    MenuMobileComponent,
    PromptComponent,
    CameraCompratorComponent
  ],

  imports: [
    BrowserModule.withServerTransition({ appId: 'Affliate-market' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
        //StoreModule.forRoot({ FeatureStateName : productsReducer }),
    StoreModule.forRoot({
      ProductsStateFromModule: productsReducer,
      SourcesStateFromModule: sourcesReducer,
      ContactsStateFromModule: contactsReducer,
      SettingsStateFromModule: settingsReducer,
      NewslettersStateFromModule: newslettersReducer,
    }),
    EffectsModule.forRoot([
      ProductsEffects,
      SourcesEffects,
      ContactsEffects,
      SettingsEffects,
      NewslettersEffects]),
    StoreDevtoolsModule.instrument(),
    HttpClientModule,
    FormsModule,
    MatGridListModule,
    NgxSkeletonLoaderModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatChipsModule,
    A11yModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      //enabled: environment.production,
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      //registrationStrategy: 'registerWhenStable:30000'
    }),

  ],
  exports: [
    MatSlideToggleModule,
    MatChipsModule
  ],
  providers: [DatePipe, Meta, CookieService, jwtInterceptorProvider, ErrorInterceptor,
    // {provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() { }

}
