import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const logFormat = 'background: maroon; color: white';
        this.authService.signOut();
        this.router.navigate(['/login']);
      //   if (err.status === 401) {
      //     // auto logout if 401 response returned from api

      //   }

      //   if (DOMException instanceof HttpErrorResponse) {
      //     switch (DOMException.status) {

      //         case HttpError.BadRequest:
      //             console.error('%c Bad Request 400', logFormat);
      //             break;

      //         case HttpError.Unauthorized:
      //             console.error('%c Unauthorized 401', logFormat);
      //             window.location.href = '/login' + window.location.hash;
      //             break;



      //         case HttpError.TimeOut:
      //             // Handled in AnalyticsExceptionHandler
      //             console.error('%c TimeOut 408', logFormat);
      //             break;


      //         case HttpError.InternalServerError:
      //             console.error('%c big bad 500', logFormat);
      //             break;
      //     }
      // }

        const error = err.error.error || err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}

export const errorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true
};

export class HttpError{
  static BadRequest = 400;
  static Unauthorized = 401;
  static Forbidden = 403;
  static NotFound = 404;
  static TimeOut = 408;
  static Conflict = 409;
  static InternalServerError = 500;
}
