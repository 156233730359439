import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';

let httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  jwtToken!: string | null;
  public host = environment.host;
  user!: User;

  constructor(private httpClient: HttpClient) { }
  userObservable!: Observable<User>;

  getAllUsers(): Observable<User[]> {
    const host = environment.host;
    console.log('getAllUsers');
    this.addJwtToHeaders();
    return this.httpClient.get<User[]>(host + '/users', httpOptions);
  }

  searchUsers(searchKeyWords: string): Observable<User[]> {
    const host = environment.host;
    console.log("bb" + searchKeyWords);
    if (searchKeyWords) {
      /*return this.httpClient.get<User[]>(host + '/users?name_like=' + searchKeyWords);*/
      return this.httpClient.get<User[]>(host + '/users/searchUsers/' + searchKeyWords);
    } else {
      return this.httpClient.get<User[]>(host + '/users');
    }
  }

  getUserByName(name: string): Observable<User> {
    const host = environment.host;
      return this.httpClient.get<User>(host + '/users/getUserByName/' + name);
  }

   deleteUser(user: User): Observable<void> {
     console.log('deleteUser');
     const host = environment.host;
     this.addJwtToHeaders();
     return this.httpClient.delete<void>(host + '/users/' + user._id);
   }

  save(user: User): Observable<User> {
    this.addJwtToHeaders();
    const host = environment.host;
    return this.httpClient.post<User>(host + '/users/', user, httpOptions);
  }

  getUser(_id: string): Observable<User> {
    const host = environment.host;
    this.addJwtToHeaders();
    console.log("t_tdqsihfosihdshfdskn");
    console.log(_id);

    return this.httpClient.get<User>(host + '/users/' + _id, httpOptions);
  }

  updateUser(user: User): Observable<User> {
    const host = environment.host;
    this.addJwtToHeaders();
    return this.httpClient.put<User>(host + '/users/' + user._id, user, httpOptions);
  }

  checkUserNameAndPassword(pseudo: string, password: string): Observable<User> {
    const host = environment.host;
    //this.addJwtToHeaders();
    console.log(pseudo);
    console.log(password);
      console.log(host + '/users/getUserByAuthentification/' + pseudo + '/' + password)
      return this.httpClient.get<User>(host + '/users/getUserByAuthentification/' + pseudo + '/' + password, httpOptions);

  }


  addJwtToHeaders(): void {
    this.jwtToken = localStorage.getItem('access_token');
    console.log(this.jwtToken);
    const auth = httpOptions.headers.get('Authorization');
    console.log(auth);

    if (!auth) {


      if (!this.jwtToken) return;
      httpOptions.headers = httpOptions.headers.append(
        'Authorization',
        `${this.jwtToken}`
      );
    }
  }

  login(username: string, password: string) {
    console.log("pppppppppppppppppppp");

    return this.httpClient.post<User>(this.host + '/login', { username, password }).pipe(
      map(user => {
        // login successful if there's a jwt token in the response
        console.log("user");
        console.log(user);

        if (user && user.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('access_token', JSON.stringify(user.access_token));
          this.user = user;
        }
        return user;
      })
    );
  }

   inscription(user : User) {
    console.log("pppppppppppppppppppp");

    return this.httpClient.post<User>(this.host + '/users', user).pipe(
      map(user => {
        // login successful if there's a jwt token in the response
        console.log("user");
        console.log(user);

        if (user && user.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('access_token', JSON.stringify(user.access_token));
          this.user = user;
        }
        return user;
      })
    );
    }
}

